
import BaseLayout from './BaseLayout.vue'
import { IonButton, alertController } from "@ionic/vue"
import { vedaService } from '../services/vedaService'
import { userService } from '../services/userService'

export default {
  name: 'RestartVeda',
  components: {
    IonButton,
    BaseLayout
  },
  computed: {
    vedaSn: () => {
      return vedaService.getVedaSN()
    }
  },
  methods: {
    restartVeda: async () => {
      const alert = await alertController.create({
        cssClass: 'restart-alert',
        header: 'Are you sure?',
        message: 'This will send a restart message to the VEDA, which can take the device temporarily offline.',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary'
          },
          {
            text: 'Restart Anyway',
            cssClass: 'primary',
            handler: async () => {
              const response = await vedaService.rebootDevice()
              if (response && response.data && response.data.data) {
                // alert(response.data.data)
                userService.openToast(response.data.data, 'light')
              }
            }
          }
        ]
      })
      alert.present()
      
    }
  }
}
