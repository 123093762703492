<template>
  <base-layout page-title="Restart">
    <template v-slot:main-content>
      <ion-item>
        <ion-list>
          <ion-text>
            <div>
              <h4>
                Confirm you want to restart VEDA with Serial Number: &nbsp;
              </h4>
            </div>
          </ion-text>
        </ion-list>
        <ion-list>
          <div>
            <h4>
              {{ vedaSn }}
            </h4>
          </div>
          <!-- Dummy QRCode because why not -->
          <!-- <img style="width:250px" src="../assets/img/qrcode.png"/> -->
        </ion-list>
      </ion-item>
      <ion-button @click="restartVeda()" expand="block" fill="outline">RESTART VEDA</ion-button>
    </template>
  </base-layout>
</template>

<script lang="ts">
import BaseLayout from './BaseLayout.vue'
import { IonButton, alertController } from "@ionic/vue"
import { vedaService } from '../services/vedaService'
import { userService } from '../services/userService'

export default {
  name: 'RestartVeda',
  components: {
    IonButton,
    BaseLayout
  },
  computed: {
    vedaSn: () => {
      return vedaService.getVedaSN()
    }
  },
  methods: {
    restartVeda: async () => {
      const alert = await alertController.create({
        cssClass: 'restart-alert',
        header: 'Are you sure?',
        message: 'This will send a restart message to the VEDA, which can take the device temporarily offline.',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary'
          },
          {
            text: 'Restart Anyway',
            cssClass: 'primary',
            handler: async () => {
              const response = await vedaService.rebootDevice()
              if (response && response.data && response.data.data) {
                // alert(response.data.data)
                userService.openToast(response.data.data, 'light')
              }
            }
          }
        ]
      })
      alert.present()
      
    }
  }
}
</script>
